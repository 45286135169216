import * as React from "react";
import '../styles/components/_merchlist.scss';

const MerchList = (props) => (
	<div className="MerchList p">
		{props.children}
	</div>
);

const MerchItem = ({children, url, caption}) => (
	<div className="MerchItem">
		<a href={url}>
			{children}
		</a><br />
		<div className="MerchItem_caption">{caption}</div>
	</div>
);

export {MerchList, MerchItem};
