import * as React from "react";
// import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import {MerchList, MerchItem} from "../components/MerchList";
// import { url } from "inspector";

const MerchPage = () => {
	return (
		<Layout>
			<Seo title="Merch/Shop" />
			<h2>Merch/Shop</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/IMGP1843.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

			<h3>Got merch?</h3>
			<p>
				I have merch in two different stores.
			</p>

			<h4>Bandcamp</h4>
			<p>
				You can visit the <a href="//jesserivest.bandcamp.com/merch">merch page at my Bandcamp store</a>, or click on any item below.
				I fulfill these orders myself; as such,
				I may have to mark something as <span className="italic">sold out</span> when I'm travelling or otherwise unavailable.
			</p>
			<MerchList>
				<MerchItem
				url="//jesserivest.bandcamp.com/album/distant-december"
				caption="Distant December"
				>
					<StaticImage
					src="../images/merch/Distant December.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivest.bandcamp.com/album/seventeen-oh-two-oh-six"
				caption="17.02.06"
				>
					<StaticImage
					src="../images/merch/Seventeen Oh-Two Oh-Six.jpg"
					alt="Jesse Rivest - Seventeen Oh-Two Oh-Six - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivest.bandcamp.com/merch/handwritten-lyric-song-of-your-choice"
				caption="lyrics handwritten"
				>
					<StaticImage
					src="../images/merch/lyrics handwritten.jpg"
					alt="Jesse Rivest - handwritten lyrics - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
			</MerchList>

			<h4>Big Cartel</h4>
			<p>
				Before you visit <a href="//jesserivestmusicmerch.bigcartel.com/">Jesse Rivest Music Merch at Big Cartel</a>,
				take a look at the colours below and <span className="italic">note the colour name</span> you prefer.
				Clicking on any item below will bring you to the correct product and there you should choose your
				preferred colour by name (no visual sample/mockup images there).
				These orders are fulfilled by Printful.
				<br />
				<span className="italic">If you don't see your country in the shipping list, contact me and I can add it</span>.
			</p>
			<MerchList>
				{/* Dark colours */}
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-dark"
				caption="Navy"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - dark/unisex-staple-t-shirt-navy-front-653839c13beee.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-dark"
				caption="Navy"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - my photos/20231028_120545.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-dark"
				caption="Navy"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - my photos/20231028_115227.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-dark"
				caption="True Royal"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - dark/unisex-staple-t-shirt-true-royal-front-653839c13c9b1.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-dark"
				caption="Asphalt"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - dark/unisex-staple-t-shirt-asphalt-front-653839c13d57b.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>

				{/* Light colours */}
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-light"
				caption="Pink"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - light/unisex-staple-t-shirt-pink-front-6538398e5ef0b.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-light"
				caption="Pink"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - my photos/20231028_120420.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-light"
				caption="Pink"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - my photos/20231028_115233.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-light"
				caption="Mauve"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - light/unisex-staple-t-shirt-mauve-front-6538398e5dd11.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-light"
				caption="Burnt Orange"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - light/unisex-staple-t-shirt-burnt-orange-front-6538398e5e466.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-light"
				caption="Soft Cream"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - light/unisex-staple-t-shirt-soft-cream-front-6538398e5fee9.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
				<MerchItem
				url="//jesserivestmusicmerch.bigcartel.com/product/distant-december-unisex-t-shirt-light"
				caption="Ash"
				>
					<StaticImage
					src="../images/merch/Distant December - tee shirts - light/unisex-staple-t-shirt-ash-front-6538398e60f72.jpg"
					alt="Jesse Rivest - Distant December - merch image"
					placeholder="blurred"
					layout="constrained"
					className=""
					imgClassName="img--bordered-small"
					width={150}
					height={150}
					/>
				</MerchItem>
			</MerchList>

		</Layout>
	);
}

export default MerchPage;
